import { Box, Grid, Paper, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { groupBy, isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import AppCard from "./components/AppCard";
import { Alert } from "@mui/material";
import AppSelection from "./components/AppSelection";
import { filterDeletedApps, filterDeletedAppables, getShowDeleted, } from "../../utils/utils";
import { MemberNavWrapper } from "./components/MemberNavWrapper";
import MemberHeader from "./components/MemberHeader";
var MemberApps = function (_a) {
    var _b, _c, _d, _e;
    var response_data = _a.response_data, serverErrors = _a.errors, flash = _a.flash;
    var member = response_data.member, all_documents = response_data.all_documents, lead_types = response_data.lead_types, insurance_carriers = response_data.insurance_carriers, agents = response_data.agents, states = response_data.states, app_dispositions = response_data.app_dispositions;
    var _f = useState(true), menuOpen = _f[0], toggleMenuOpen = _f[1];
    var _g = useState(false), openMessage = _g[0], setOpenMessage = _g[1];
    var _h = useState(function () { return getShowDeleted(); }), showDeleted = _h[0], setShowDeleted = _h[1];
    var apps = groupBy((_b = member.apps) === null || _b === void 0 ? void 0 : _b.filter(function (el) {
        return filterDeletedAppables(el, showDeleted);
    }), "appable_type");
    var appTypes = Object.keys(apps).map(function (el) {
        switch (el) {
            case "App\\Models\\LifeInsuranceApplication":
                return "life_insurance_applications";
            case "App\\Models\\HealthInsuranceApplication":
                return "health_insurance_applications";
            case "App\\Models\\FinancialApplication":
                return "financial_applications";
        }
    });
    var _j = useState((_c = appTypes === null || appTypes === void 0 ? void 0 : appTypes[0]) !== null && _c !== void 0 ? _c : ""), appType = _j[0], setAppTypes = _j[1];
    var appOptions = {
        life_insurance_applications: {
            title: "Life Insurance",
            appType: "App\\Models\\LifeInsuranceApplication",
            noteType: "life",
            fileType: "life_insurance_applications",
        },
        health_insurance_applications: {
            title: "Health Insurance",
            appType: "App\\Models\\HealthInsuranceApplication",
            noteType: "health",
            fileType: "health_insurance_applications",
        },
        financial_applications: {
            title: "Financial Insurance",
            appType: "App\\Models\\FinancialApplication",
            noteType: "financial",
            fileType: "financial_applications",
        },
    };
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    useEffect(function () {
        localStorage.setItem("showDeleted", JSON.stringify(showDeleted));
    }, [showDeleted]);
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(MemberNavWrapper, { member: member, all_documents: all_documents, showDeleted: showDeleted }))),
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(MemberHeader, { member: member })),
            React.createElement(Grid, { item: true, xs: 9 }),
            React.createElement(Grid, { item: true, xs: 12 }, openMessage && (React.createElement(Box, { mb: 1 },
                React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                    React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message))))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mb: 1 },
                    React.createElement(AppSelection, { appType: appType, setAppType: setAppTypes, appTypes: appTypes, apps: apps, appOptions: appOptions, setShowDeleted: setShowDeleted, showDeleted: showDeleted }))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "subtitle2" },
                        "*Disclaimer: In order to promote an app to a policy, the disposition",
                        [
                            "financial_applications",
                            "life_insurance_applications",
                        ].includes(appType)
                            ? " delivery requirements sent to carrier "
                            : " Approved ",
                        "must be used.")), (_e = (_d = member[appType]) === null || _d === void 0 ? void 0 : _d.filter(function (el) {
                return filterDeletedApps(el, showDeleted);
            })) === null || _e === void 0 ? void 0 :
                _e.map(function (app) {
                    var _a, _b, _c;
                    return (React.createElement(Box, { mb: 1, key: app.id, id: "app_".concat(app.id) },
                        React.createElement(Paper, null,
                            React.createElement(AppCard, { member: member, app: app, leadTypes: lead_types, agents: agents, states: states, appType: (_a = appOptions === null || appOptions === void 0 ? void 0 : appOptions[appType]) === null || _a === void 0 ? void 0 : _a.appType, noteType: (_b = appOptions === null || appOptions === void 0 ? void 0 : appOptions[appType]) === null || _b === void 0 ? void 0 : _b.noteType, fileType: (_c = appOptions === null || appOptions === void 0 ? void 0 : appOptions[appType]) === null || _c === void 0 ? void 0 : _c.fileType, insuranceCarriers: insurance_carriers, dispositions: app_dispositions }))));
                })))));
};
export default MemberApps;

import PageTitle from "../components/PageTitle";
import React from "react";
import { Box, Paper, Grid, Typography, } from "@mui/material";
import AppCard from "../Pages/Member/components/AppCard";
var AppTable = function (_a) {
    var data = _a.data, leadTypes = _a.leadTypes, title = _a.title, noteType = _a.noteType, appType = _a.appType, fileUploadDirectory = _a.fileUploadDirectory, agents = _a.agents, dispositions = _a.dispositions, states = _a.states, insuranceCarriers = _a.insuranceCarriers;
    return (React.createElement(Grid, { item: true, xs: 12 },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: title })),
        React.createElement(Box, { mb: 2 }, React.createElement(Typography, { variant: "subtitle2" },
            "*Disclaimer: In order to promote an app to a policy, the disposition",
            [
                "financial_applications",
                "life_insurance_applications",
            ].includes(fileUploadDirectory)
                ? " delivery requirements sent to carrier "
                : " Approved ",
            "must be used.")), data === null || data === void 0 ? void 0 :
        data.map(function (app) { return (React.createElement(Box, { mb: 1, key: app.id },
            React.createElement(Paper, null,
                React.createElement(AppCard, { member: app === null || app === void 0 ? void 0 : app.member, app: app, leadTypes: leadTypes, agents: agents, appType: appType, noteType: noteType, fileType: fileUploadDirectory, states: states, insuranceCarriers: insuranceCarriers, dispositions: dispositions })))); })));
};
export default AppTable;

import React, { useState } from "react";
import { router } from "@inertiajs/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import axios from "axios";
import GenericAutoComplete from "./GenericAutoComplete";
var ModalAbsFilters = function (_a) {
    var _b, _c, _d;
    var filters = _a.filters, setFilters = _a.setFilters;
    var _e = useState(false), openFiltersModal = _e[0], setOpenFiltersModal = _e[1];
    var _f = useForm(), control = _f.control, formErrors = _f.formState.errors, handleSubmit = _f.handleSubmit;
    var getFiltersArray = function () {
        filters.length == 0 && setFilters
            ? axios
                .get("/user/abs/get-filters")
                .then(function (res) {
                setFilters(res.data.filters);
            })
                .then(function () { return setOpenFiltersModal(true); })
                .catch(function (err) {
                console.log("err", err);
            })
            : setOpenFiltersModal(true);
    };
    var submitFilters = function (formData) {
        var _a, _b, _c, _d, _e;
        setOpenFiltersModal(false);
        router.post("/user/abs", {
            filters: {
                onboarding_statuses: (_a = formData.onboarding_statuses.map(function (onboarding_status) { return onboarding_status.name; })) !== null && _a !== void 0 ? _a : [],
                statuses: (_b = formData.statuses.map(function (status) { return status.name; })) !== null && _b !== void 0 ? _b : [],
                amac_recruiters: (_c = formData.amac_recruiters.map(function (amac_recruiter) { return amac_recruiter.id; })) !== null && _c !== void 0 ? _c : [],
                states: (_d = formData.states.map(function (state) { return state.id; })) !== null && _d !== void 0 ? _d : [],
                carriers: (_e = formData.carriers.map(function (carrier) { return carrier.id; })) !== null && _e !== void 0 ? _e : [],
            },
        });
    };
    var handleCloseFilters = function () {
        setOpenFiltersModal(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: getFiltersArray, variant: "contained", style: {
                backgroundColor: "#1871BF",
                color: "#FFF",
            } }, "Filter Brokers"),
        React.createElement(Modal, { open: openFiltersModal, onClose: handleCloseFilters },
            React.createElement(Dialog, { open: openFiltersModal, onClose: handleCloseFilters },
                React.createElement(DialogTitle, null, "Filter Brokers"),
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(Grid, { xs: 12, my: 2 },
                        React.createElement(GenericAutoComplete, { name: "statuses", label: "Filter By Status", options: (_b = filters === null || filters === void 0 ? void 0 : filters.statuses) === null || _b === void 0 ? void 0 : _b.map(function (status) {
                                return {
                                    id: status.id,
                                    name: status.name,
                                };
                            }), control: control, value: [], placeholder: "Select Statuses", rules: {
                                required: false,
                            }, error: "statuses" in formErrors, limitTags: 500 })),
                    React.createElement(Grid, { xs: 12, my: 2 },
                        React.createElement(GenericAutoComplete, { name: "onboarding_statuses", label: "Filter By Onboarding Status", options: (_c = filters === null || filters === void 0 ? void 0 : filters.onboarding_statuses) === null || _c === void 0 ? void 0 : _c.map(function (onboarding_status) {
                                return {
                                    id: onboarding_status === null || onboarding_status === void 0 ? void 0 : onboarding_status.id,
                                    name: onboarding_status.name,
                                };
                            }), control: control, value: [], placeholder: "Select Onboarding Statuses", rules: {
                                required: false,
                            }, error: "onboarding_statuses" in formErrors, limitTags: 500 })),
                    React.createElement(Grid, { xs: 12, my: 2 },
                        React.createElement(GenericAutoComplete, { name: "amac_recruiters", label: "Filter By AMAC Recruiters", options: (_d = filters === null || filters === void 0 ? void 0 : filters.amac_recruiters) === null || _d === void 0 ? void 0 : _d.map(function (amac_recruiter) {
                                return {
                                    id: amac_recruiter.id,
                                    name: amac_recruiter.name,
                                };
                            }), control: control, value: [], placeholder: "Select AMAC Recruiters", rules: {
                                required: false,
                            }, error: "amac_recruiters" in formErrors, limitTags: 500 })),
                    React.createElement(Grid, { xs: 12, my: 2 },
                        React.createElement(GenericAutoComplete, { name: "states", label: "Filter By State", options: filters === null || filters === void 0 ? void 0 : filters.states, control: control, value: [], placeholder: "Select States", rules: {
                                required: false,
                            }, error: "states" in formErrors, limitTags: 500 })),
                    React.createElement(Grid, { xs: 12, my: 2 },
                        React.createElement(GenericAutoComplete, { name: "carriers", label: "Filter By Carriers", options: filters === null || filters === void 0 ? void 0 : filters.insurance_carriers, control: control, value: [], placeholder: "Select Carriers", rules: {
                                required: false,
                            }, error: "carriers" in formErrors, limitTags: 500 }))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleCloseFilters, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitFilters), color: "primary" }, "Filter"))))));
};
export default ModalAbsFilters;

import React, { useState } from "react";
import { Button, Box, Modal, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, } from "@mui/material";
import { router } from "@inertiajs/react";
import { Controller, useForm } from "react-hook-form";
import GenericControllerDropdownInput from "../../../components/GenericControllerDropdownInput";
import { dispositionFollowUp, rosemarkAppDispositionFollowUp, rosemarkLeadDispositionFollowUp, } from "../../../utils/dateUtils";
import GenericControllerCheckboxInput from "../../../components/GenericControllerCheckboxInput";
var SetDisposition = function (_a) {
    var _b, _c;
    var data = _a.data, options = _a.options, url = _a.url, title = _a.title, label = _a.label, name = _a.name, id = _a.id, buttonName = _a.buttonName, _d = _a.backgroundColor, backgroundColor = _d === void 0 ? "#392869" : _d, _e = _a.appType, appType = _e === void 0 ? "" : _e;
    var _f = useForm(), control = _f.control, handleSubmit = _f.handleSubmit;
    var _g = useState(false), open = _g[0], setOpen = _g[1];
    var followUpDate = (_b = data === null || data === void 0 ? void 0 : data.follow_up_date) !== null && _b !== void 0 ? _b : "";
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (postData) {
        var _a, _b;
        setOpen(false);
        if (appType !== "") {
            postData.type = appType;
            postData.route = window.location.href;
        }
        postData.follow_up_date = [5 /*financial*/, 11 /*life*/].includes((_a = data === null || data === void 0 ? void 0 : data.lead_type_id) !== null && _a !== void 0 ? _a : (_b = data === null || data === void 0 ? void 0 : data.lead) === null || _b === void 0 ? void 0 : _b.lead_type_id)
            ? url.includes("app") // TODO: better way to handle checking if app needed, will break if implementing for policies.
                ? rosemarkAppDispositionFollowUp(postData.disposition_id.toString())
                : rosemarkLeadDispositionFollowUp(postData.disposition_id.toString())
            : dispositionFollowUp(postData.disposition_id.toString());
        router.post(url, postData);
    };
    return (React.createElement(Box, null,
        React.createElement(Button, { variant: "contained", style: {
                backgroundColor: backgroundColor,
                color: "#FFF",
            }, onClick: handleChange, fullWidth: true, size: "small" }, buttonName),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, { id: "form-dialog-title" }, title),
                React.createElement(DialogContent, null,
                    React.createElement(GenericControllerDropdownInput, { name: name, rules: {
                            required: true,
                        }, control: control, id: "".concat(name, "_id"), label: label, defaultValue: (_c = data[name]) !== null && _c !== void 0 ? _c : null, handleChange: handleChange, options: options
                            .filter(function (option) {
                            var _a, _b;
                            return appType ==
                                "App\\Models\\LifeInsuranceApplication"
                                ? true
                                : [
                                    5 /*financial*/, 11 /*life*/,
                                ].includes((_a = data === null || data === void 0 ? void 0 : data.lead_type_id) !== null && _a !== void 0 ? _a : (_b = data === null || data === void 0 ? void 0 : data.lead) === null || _b === void 0 ? void 0 : _b.lead_type_id)
                                    ? [1, 2, 5].includes(option.disposition_group_id)
                                    : ![4, 5].includes(option.disposition_group_id);
                        })
                            .sort(function (x, y) {
                            return x.id == 12 ? -1 : y.id == 12 ? 1 : 0;
                        })
                            .map(function (opt) {
                            var disp_name = appType == "" &&
                                opt.id == 2 &&
                                [5, 11].includes(data.lead_type_id)
                                ? "Application Taken On Client"
                                : opt.name;
                            return (React.createElement(MenuItem, { key: opt.id, value: opt.id }, disp_name));
                        }) }),
                    React.createElement(GenericControllerCheckboxInput, { control: control, defaultChecked: false, name: "ignore_disposition_email", label: "Disable automatic disposition email for this change." }),
                    React.createElement(Controller, { control: control, name: "id", defaultValue: id, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } }),
                    React.createElement(Controller, { control: control, name: "follow_up_date", defaultValue: followUpDate, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } }),
                    React.createElement(Controller, { control: control, name: "route", defaultValue: window.location.href, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Change"))))));
};
export default SetDisposition;
